body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.apartment-item
  .MuiSvgIcon-root.MuiSvgIcon-colorError.MuiSvgIcon-fontSizeMedium {
  display: none;
  width: 0px;
}

.apartment-item
  button.MuiButtonBase-root.MuiButton-root
  span.MuiButton-endIcon.MuiButton-iconSizeMedium {
  margin-right: 0px;
  margin-left: 0px;
  display: none;
}

.apartment-item
  button.MuiButtonBase-root.MuiButton-root
  .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-root.MuiCheckbox-colorPrimary {
  padding-right: 0px;
}

.apartment-item
  button.MuiButtonBase-root.MuiButton-root.MuiButton-outlinedExtra
  .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary {
  padding-right: 0px !important;
}

.gallery-image {
  max-width: 100%;
  width: fit-content;
  height: auto;
  object-fit: cover;
  margin: auto;
  display: block;
  max-height: 80vh;
}

/* PWA Prompt UI */
.noScroll {
  overflow: hidden;
}
.pwaPromptOverlay {
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in;
  width: 100vw;
  z-index: 999999;
}
.pwaPromptOverlay.visible {
  opacity: 1;
  display: block;
}
.pwaPromptOverlay.hidden {
  pointer-events: none;
  touch-action: none;
}
@media (prefers-color-scheme: dark) {
  .pwaPromptOverlay.modern {
    background: rgba(10, 10, 10, 0.5);
    color: rgba(235, 235, 245, 0.6);
 }
}
.pwaPrompt {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(250, 250, 250, 0.8);
  border-radius: 10px;
  bottom: 0;
  color: black;
  filter: brightness(1.1);
  left: 0;
  margin: 0 8px 10px;
  overflow: hidden;
  position: fixed;
  transform: translateY(calc(100% + 10px));
  transition: transform 0.4s cubic-bezier(0.4, 0.24, 0.3, 1);
  width: calc(100vw - 16px);
  z-index: 999999;
}
.pwaPrompt.visible {
  transform: translateY(0);
  display: block;
}
.pwaPrompt.hidden {
  pointer-events: none;
  touch-action: none;
}
.pwaPrompt.modern {
  background: rgba(255, 255, 255, 0.6);
  filter: brightness(1.6);
}
@media (prefers-color-scheme: dark) {
  .pwaPrompt.modern {
    background: rgba(65, 65, 65, 0.7);
    filter: brightness(1.1);
 }
}
.pwaPromptHeader {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 0.5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 13px 16px;
}
.modern .pwaPromptHeader {
  border-color: rgba(60, 60, 67, 0.29);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptHeader {
    border-color: rgba(140, 140, 140, 0.7);
 }
}
.pwaPromptHeader .pwaPromptTitle {
  color: #333;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.125;
  margin: 0;
  padding: 0;
}
.modern .pwaPromptHeader .pwaPromptTitle {
  color: rgba(0, 0, 0, 1);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptHeader .pwaPromptTitle {
    color: rgba(255, 255, 255, 1);
 }
}
.pwaPromptHeader .pwaPromptCancel {
  cursor: pointer;
  color: #2d7cf6;
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
}
.modern .pwaPromptHeader .pwaPromptCancel {
  color: rgba(0, 85, 179, 1);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptHeader .pwaPromptCancel {
    color: rgba(9, 132, 255, 1);
 }
}
.pwaPromptBody {
  display: flex;
  width: 100%;
}
.pwaPromptBody .pwaPromptDescription {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 0.5px;
  color: inherit;
  margin: 0 16px;
  padding: 16px;
  width: 100%;
}
.modern .pwaPromptBody .pwaPromptDescription {
  border-color: rgba(60, 60, 67, 0.29);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptBody .pwaPromptDescription {
    border-color: rgba(140, 140, 140, 0.7);
 }
}
.pwaPromptCopy {
  color: #7b7b7a;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
  line-height: 17px;
  margin: 0;
  padding: 0;
}
.pwaPromptCopy.bold {
  font-weight: 600;
}
.modern .pwaPromptCopy {
  color: rgba(60, 60, 67, 0.6);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptCopy {
    border-color: rgba(235, 235, 245, 0.6);
    color: rgba(235, 235, 245, 0.6);
 }
}
.pwaPromptInstruction {
  color: inherit;
  margin: 0 16px;
  padding: 16px;
}
.pwaPromptInstruction .pwaPromptInstructionStep {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 16px;
}
.pwaPromptInstruction .pwaPromptInstructionStep:last-of-type {
  margin-bottom: 0;
}
.pwaPromptInstruction .pwaPromptShareIcon, .pwaPromptInstruction .pwaPromptHomeIcon {
  flex: 0 0 auto;
  height: 30px;
  margin-right: 32px;
  width: 25px;
}
.pwaPromptInstruction .pwaPromptHomeIcon {
  color: #2d7cf6;
}
.modern .pwaPromptInstruction .pwaPromptHomeIcon {
  color: black;
  fill: black;
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptInstruction .pwaPromptHomeIcon {
    color: white;
    fill: white;
 }
}
.pwaPromptInstruction .pwaPromptShareIcon {
  color: #2d7cf6;
  fill: #2d7cf6;
}
.modern .pwaPromptInstruction .pwaPromptShareIcon {
  color: rgba(0, 85, 179, 1);
  fill: rgba(0, 85, 179, 1);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptInstruction .pwaPromptShareIcon {
    color: rgba(9, 132, 255, 1);
    fill: rgba(9, 132, 255, 1);
 }
}

.text-center{
  text-align: center;
}

.buttons-group{
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fixed-status-color
  button.MuiButtonBase-root:not(button.MuiButton-containedError, button.MuiButton-containedSuccess) {
  background-color: #fcfac6 !important;
  border-color: #e4e100 !important;
}
